@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
}

.progress-button-container {
    border-radius: 10px;
    border: none;
    overflow: hidden;
    position: relative;
    height: auto;
}

.progress-container {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;

    border-radius: 10px;
    border: 2px white;
    overflow: hidden;
}

.progress-title-container {
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    bottom: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: transparent;
}


.progress-title {
    margin: 0px;
    padding: 20px 20px 20px 20px;

    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

.progress-value {
    margin: 0px;
    padding: 10px 0px 10px 0px;

    font-size: 60px;
    font-weight: normal;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

/* Grid version commented out */

/* .grid-container-home {
    display: grid;
    background-image: linear-gradient(to bottom, #111111 0%, #1C2025 100%);
    height: 100vh;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    overflow: auto;
    align-items: center;
    grid-template-rows: 45px auto 90px;
    grid-gap: 5px;
    -ms-grid-rows: 50px 1fr 90px;
} */

/* .grid-item-home {
    margin: auto;
}

.grid-item-home.header {
    margin: 0;
  } */


/* .grid-container-home h2 {
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin: 0 auto 1rem;
} */

/*
.grid-item-bottom {
  grid-row: 3 / 4;
  -ms-grid-row: 3 / 4;
} */
.endAnswers {
  line-height: normal;
  font-weight: bold;
  font-size: 2vw;
  /* font-size: 4vw; */
}

.spacer-md{
  width:10px;
  height:10px;
}

.answerItemClass{
    margin-left:1px;
    margin-right:1px;
}

.emphasizedTextAnswers {
  background-color: #000;
  font-size: 2vw;
  padding-left: 20px;
  font-family: "Oswald", sans-serif;
  padding-right: 20px;
  font-weight: 700;
}

.thanksForPlayingLittleText {
  font-size: 2vw;
}


/* Flexbox version */
::-webkit-scrollbar {
    width: 0;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.card {
    box-shadow: unset !important;
    transition: 0.3s;
    background-color: transparent !important;
    color:white;
    margin: 10px;
    padding: 10px;
    border: none !important;
}

.flex-container-home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
    background-size: cover;
}

.flex-header-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.header-left, .header-right {
  padding: 10px;
}

.header-left img {
  width: 35px;
}

.flex-content-container-home {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}

.intro-container-home {
    margin: 0 auto auto;
    text-align: center;
}

.scratch-header-home {
  max-width: 280px;
  margin: auto;
}

.scratch-image-container {
    margin: 0 auto 2em;
}

.emphasizedText {
  background-color: #000;
  font-size: 5vw;
  padding-left: 20px;
  font-family: "Oswald", sans-serif;
  padding-right: 20px;
  font-weight: 700;
}

.btn.btn-play {
  margin-top: 10px;
  color: #fff;
  background-color: #cc172c;
  width: 250px;
  font-size: 1.4em;
  font-family: "Oswald", sans-serif;
  height: 50px;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules {
  margin-top: 15px;
  color: #fff;
  background-color: #000;
  width: 120px;
  font-size: 15px;
  font-family: "Oswald", sans-serif;
  height: 40px;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-next {
    color: #fff;
    background-color: #EDAA37;
    width: 250px;
    font-size: 1.4em;
    height: 50px;
    font-weight: 100;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-next:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, .25);
}

.question-box #create-email-form label {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 300;
}

.question-box {
    background-color: white;
}

.btn.btn-default.btn-admin {
    font-weight: 300;
}

.home-logo-container {
    margin: auto;
    text-align: center;
}

img.home-logo {
    width: 70%;
    max-width: 400px;
    margin-bottom: 10%;
}

.home-logo-title {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15%;
    -webkit-transform: scaleY(1.6);
            transform: scaleY(1.6);
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 0;
}


@media (min-width: 600px) {
  .intro-container-home, .scratch-container-home {
    margin: 5% auto auto;
  }
}

@media  (max-width: 600px) {
  .scratch-image-container {
    margin: 0 auto 1em;
  }

  .flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-size: 1.5em;
  }

  .thanksForPlayingLittleText {
    font-size: 6vw;
  }

  .emphasizedText {
    font-size: 10vw;
  }
  .emphasizedTextAnswers {
    font-size: 6vw;
  }
  .endAnswers {
    font-size: 6vw;
  }
  .spacer-md{
    width:10px;
    height:30px;
  }
    .answerItemClass{
        margin:0px
    }
}

@media (min-width: 767px) {
  .scratch-header-home h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    line-height: 1.1;
  }
  .scratch-image-container {
    margin: 0 auto 30px;
  }

}

.react-confirm-alert > h1, .react-confirm-alert  {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    word-wrap: break-word;
}

#react-confirm-alert .react-confirm-alert > h1 {
    color: #000;
    margin-bottom: 10px;
}

#react-confirm-alert .react-confirm-alert h1 {
  font-size: 6vw;
}

#react-confirm-alert .react-confirm-alert {
    width:90%;
}

#react-confirm-alert .react-confirm-alert > h3 {
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-body {
  width: auto;
  text-align: center;
  max-width: 100%;
}

#react-confirm-alert .react-confirm-alert-button-group {
justify-content: center;
}

#react-confirm-alert .react-confirm-alert-button-group > button {
    background: #000;
    letter-spacing: 1.2px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 300;
}

#react-confirm-alert .react-confirm-alert-overlay {
    /*background: rgba(255, 255, 255, 0.3);*/
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    -webkit-filter: url(#gaussian-blur);
            filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    /*background: rgba(255, 255, 255, 0.9);*/
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: "Oswald", sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#react-confirm-alert .react-confirm-alert {
    margin: 0 1em 0 1em; }

#react-confirm-alert .react-confirm-alert h1 {
    font-family: "Oswald", sans-serif;
    color: #000000;
    margin-bottom: 10px;
    text-shadow: none; }

#react-confirm-alert .react-confirm-alert-button-group {
    justify-content: center; }

#react-confirm-alert .react-confirm-alert-button-group button {
    font-size: 1.8em;
    font-weight: 800;
    height: 2em;
    width: 80%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #fff;
    background-color: #cc172c;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }

/*#react-confirm-alert .react-confirm-alert-overlay {*/
/*  background: rgba(255, 255, 255, 0.3); }*/

#react-confirm-alert .react-confirm-alert-overlay {
    z-index: 1051; }

#react-confirm-alert h3 {
    font-family: "Oswald", sans-serif;
    font-size: 1.3em;
    text-align: center;
    font-weight: 300;
    color: #000000;
    line-height: 1.3em; }

.confirmAlertImage {
    max-width: 400px;
    max-height: 200px;
}

/*PROPERTIES*/
/*HOME/LOGIN PAGES*/

#root {
  /* background: url("../images/background.png") no-repeat center center fixed; */
  background-size: cover;
  }

.header-left {
  margin-top: 1em;
  margin-left: 0.5em; }

.hero-text-container {
  padding-bottom: 1em;
  padding-top: 1em;
  margin: auto; }
  .hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
    zoom: 45%; }
  .hero-text-container .thanks-main-title {
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    padding: 0 1em 0 1em; }
    .hero-text-container .thanks-main-title h1 {
      text-shadow: none;
      font-size: 9vmax;
      line-height: 1em; }
    .hero-text-container .thanks-main-title .intro-button-wrapper {
      text-align: center; }
  .hero-text-container .login-main-subtitle, .hero-text-container .thanks-main-subtitle {
    color: white;
    text-align: center;
    font-size: 6vmax;
    font-family: "Oswald", sans-serif;
    font-weight: 600; }
  .hero-text-container .login-main-subtitle {
    padding: 0 1em 0 1em; }
  .hero-text-container .thanks-main-subtitle {
    padding: 0 0.5em 0 0.5em; }

.main-container-login .btn-info-icon {
  background: none;
  float: right;
  border: none;
  outline: none; }
  .main-container-login .btn-info-icon #info-icon {
    height: 2em; }

.main-container-login .button-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .main-container-login .button-content-container h2 {
    color: white;
    float: left;
    font-family: "Oswald", sans-serif;
    line-height: normal;
    line-height: initial;
    margin-bottom: 0;
    margin-right: 0.5em; }
  .main-container-login .button-content-container .button-image {
    float: left; }

.main-container-login .number-button {
  color: #000000;
  float: left;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  outline-width: 0; }

.main-container-login .button-container-login-number {
  position: fixed;
  bottom: 5.5em; }

.main-container-login .button-content-container-number {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.main-container-login .button-item-container-number {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 5.5em;
  height: 3.1em; }

.main-container-login .button-container-login {
  position: fixed;
  bottom: 0; }

.main-container-login .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 5.5em; }

.lock-background {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  overscroll-behavior-y: none; }

#scratch-image {
  width: 280px;
  height: 380px; }

.flex-container-home .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 4em;
  height: 4em; }

.flex-container-home .loader hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  -webkit-animation: spin 2s ease infinite;
          animation: spin 2s ease infinite; }

.flex-container-home .loader :first-child {
  background: rgba(0, 0, 255, 0.9);
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s; }

.flex-container-home .loader :nth-child(2) {
  background: rgba(0, 0, 255, 0.9);
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

.flex-container-home .loader :nth-child(3) {
  background: rgba(0, 0, 255, 0.9);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }

.flex-container-home .loader :last-child {
  background: rgba(0, 0, 255, 0.9); }

@-webkit-keyframes spin {
  0%, 100% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  25% {
    -webkit-transform: translate(160%);
            transform: translate(160%); }
  50% {
    -webkit-transform: translate(160%, 160%);
            transform: translate(160%, 160%); }
  75% {
    -webkit-transform: translate(0, 160%);
            transform: translate(0, 160%); } }

@keyframes spin {
  0%, 100% {
    -webkit-transform: translate(0);
            transform: translate(0); }
  25% {
    -webkit-transform: translate(160%);
            transform: translate(160%); }
  50% {
    -webkit-transform: translate(160%, 160%);
            transform: translate(160%, 160%); }
  75% {
    -webkit-transform: translate(0, 160%);
            transform: translate(0, 160%); } }

.flex-container-home .scratch-container-home {
  width: 50vmax !important;
  max-width: 280px; }
  .flex-container-home .scratch-container-home .scratch-image-container .scratch-image {
    width: 100%;
    height: 100%; }
    .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container {
      width: 100% !important; }
      .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container .ScratchCard__Canvas {
        width: 100% !important;
        height: 100% !important; }

.flex-container-home .flex-header-home .header-right .btn-logout {
  background-color: transparent;
  background-image: none;
  border-color: #0000ff;
  color: #0000ff;
  font-family: "Oswald", sans-serif;
  margin-top: 1em;
  margin-right: 0.5em; }

.flex-container-home .flex-content-container-home .intro-container-home, .flex-container-home .flex-content-container-home .thanks-container-home {
  margin: auto;
  /* padding-bottom: 4em;  */
}

.flex-container-home .scratch-container-home {
  margin: 2em auto auto; }
  .flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-family: "Oswald", sans-serif;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    line-height: 1; }

.flex-container-home .button-wrapper {
  display: flex;
  justify-content: center; }

.flex-container-home .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 4em; }
  .flex-container-home .button-item-container .btn-scratcher {
    border-radius: 0 !important;
    bottom: 0;
    font-size: 1.8em;
    font-weight: 800;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #000000;
    background-color: #0000ff;
    text-transform: uppercase;
  }

html,body {
    background-color: black;
}

.grid-wrapper {
  height: 100vh;
  /* background-image: url(../images/background.png); */
  background-size: cover;
  font-family: "Oswald", sans-serif;
  /*text-transform: uppercase;*/
  padding: 0;
  margin: 0;
  overflow: auto;
}

.grid-wrapper.session {
  /* background-image: url(../images/background.png); */
}
.grid-wrapper.holding {
  /* background-image: url(../images/background.png); */
  background-position: bottom right;
}

.grid-left {
  margin: auto;
}

.creator-awards-logo {
  text-align: center;
}

.creator-awards-logo img {
  width: 400px;
}

.creator-awards-logo.user img {
  margin-top: 25px;
}

.questionTextSpecial{
  text-align: center;
  font-size: 3em;
  line-height: 1;
  margin: 0;
}

.grid-header {
  color: #fff;
  padding: 5px 35px;
  margin-top: 40px;
  border: solid 1px rgba(0,0,0,0.15);
  font-size: 3em;
  text-align: center;
}

.grid-header.user {
  max-width: 350px;
  margin: 40px auto 0;
}

.grid-header.creator-vote {
  max-width: 100%;
  margin: 10px auto 0;
  background: transparent;
  color: #fff;
}

.grid-header .session-name {
  margin-bottom: 15px;
  color: #4699C5;
}

.grid-header h1 {
  text-align: center;
  font-size: 5em;
  line-height: 1;
  margin: 0;
}

.item-scoreboard{
  padding: 5px;
  margin: 20px;
  border: solid 2px white;
  text-align: center;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  border-radius: 10px;
  font-weight: 700;
}

.grid-item {
  font-size: 2em;
  padding: 10px;
  color: #000;
  background: #fff;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  border-radius: 10px;
  font-weight: 700;
  border: solid 1px black;
  text-align: center;
  box-shadow: 4px 4px;
  /* line-height: 1.3; */
  /* max-width: 500px; */
}

.session-item {
  font-size: 5em;
  color: #4699C5;
  background: #fff;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  padding: 0;
  border: solid 1px rgba(0,0,0,0.15);
  text-align: center;
  line-height: 1.3;
  /* max-width: 500px; */
}

.grid-item.creator-vote {
  background: #fff;
}

.grid-item-votes {
  float: right;
  margin-right: 20px;
  text-align: right;
}

.grid-item-company {
  float: left;
  margin-left: 20px;
  width: 75%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-container {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
	margin: auto;
  max-width: 1200px;
  display: grid;
  /* grid-template-rows: repeat(3, 100px); */
  grid-gap: 10px 50px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  padding: 0 10px 20px;
  clear: both;
}

.centerTable{
  display: table;
  height: calc(100vh - 400px);
}

.centerTextOnScreen{
  overflow: hidden;
  height: calc(100vh - 400px);
  margin-top: 200px;
  margin-bottom: 200px;
  width: 100%;
  /* height: calc(100vh - 400px); */
  /* margin: auto; */
  /* display: table; */
}

.grid-container-big-screen-side {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
  margin-top: 200px;
  margin-bottom:200px;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* max-width: 1200px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  /* grid-auto-rows: 100px; */
  /* grid-auto-columns: auto; */
  /* grid-auto-flow: row; */
  /* grid-auto-columns: 1fr 1fr; */
  padding: 10px;
  /* clear: both; */
  height: calc(100vh - 400px);
  align-content: center;
  overflow: hidden;
}

.grid-container-big-screen-top {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
  margin-top: 200px;
  margin-bottom:200px;
  /* minmax(1100px, 100%); */
  /* min-width: 1100px; */
  /* max-width: 1200px; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  /* grid-auto-rows: 100px; */
  /* grid-auto-flow: column; */
  /* grid-auto-columns: 1fr 1fr; */
  padding: 10px;
  /* clear: both; */
  height: calc(100vh - 400px);
  align-content: center;
  overflow: hidden;
}

.grid-container-big-screen-single {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
  margin-top: 200px;
  margin-bottom:200px;
  max-width: 1200px;
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-gap: 5px 5px;
  grid-auto-rows: 100px;
  /* grid-auto-flow: column; */
  /* grid-auto-columns: 1fr 1fr; */
  /* padding: 0 10px 20px; */
  /* clear: both; */
  height: calc(100vh - 400px);
  align-content: center;
  overflow: hidden;
}

.grid-item-big-screen {
  height: 150px;
  min-width: 100px;
  font-size: 2em;
  padding: 10px;
  color: #000;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  border-radius: 10px;
  font-weight: 700;
  border: solid 1px black;
  text-align: center;
  box-shadow: 4px 4px;
  overflow: hidden;
}

.grid-item-big-screen-top{
  height: 150px;
  min-width: 1100px;
  /* grid-column: 1; */
  margin: auto;
  font-size: 2em;
  padding: 10px;
  color: #000;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  border-radius: 10px;
  font-weight: 700;
  border: solid 1px black;
  text-align: center;
  box-shadow: 4px 4px;
  overflow: hidden;
}

.grid-container-big-screen-vertical{
  background: transparent;
  /* margin: 10px; */
  max-width: 99%;
  /* align-items: center; */
  align-items: center;
  align-content: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-item-big-screen-vertical {
  min-height: 100px;
  min-width: 100px;
  width: 100%;
  margin:auto;
  font-size: 2em;
  padding: 10px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  background: #fff;
  /* text-shadow: 0 2px 0 rgba(110,133,156,0.18); */
  border-radius: 10px;
  font-weight: 700;
  border: solid 1px black;
  /* box-shadow: 4px 4px; */
}

.grid-container-holding {
  display: grid;
  max-width: 95%;
  margin: auto;
  grid-template-rows: 400px 50px 100px;
  grid-template-areas:
  "top-logo top-text"
  "middle middle"
  "bottom bottom" ;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 50px;
  -webkit-transform: translateY(15%);
          transform: translateY(15%);
}

.session .grid-container-holding {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  grid-template-rows: 1fr;
  grid-template-areas:
  "top-logo top-text";
}

.top-logo {
  grid-area: top-logo;
}

.session .top-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-text {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 40px;
  grid-area: top-text;
}

.middle {
  grid-area: middle;
}

.bottom {
  grid-area: bottom;
}

.grid-item-holding {
  background: transparent;
}

.top-text h1 {
  color: #fff;
  text-align: left;
  font-size: 13em;
  line-height: .8;
  padding-top: 35px;
}

.top-text.no-connection h1 {
  font-size: 9em;
}

.grid-item-holding img{
  max-width: 400px;
}

.creator-awards-logo-holding {
  text-align: center;
}

.session .creator-awards-logo-holding img {
  max-width: 70%;
}

hr.holding {
  border-top: 10px solid #fff;
  width: 90%;
}

.grid-footer {
  font-size: 3em;
  color: #000;
  text-shadow: 0 2px 0 rgba(110,133,156,0.18);
  padding: 10px;
  background: #FDEC6A;
  border: solid 1px rgba(0,0,0,0.15);
  text-align: center;
  line-height: 1.5;
  width: 60%;
  margin: auto;
}

.grid-footer h1 {
  font-size: 1.5em;
  text-align: center;
  color: #000;
  line-height: 1;
  margin: 0;
  padding-top: 2px;
}

.grid-container-another {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.grid-container-outer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
}

.grid-container-inner {
  background: transparent;
  box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1);
	margin: 0px 5% 0px 50px;
  width: 90%;
  display: grid;
  grid-template-rows: repeat(6, 100px);
  grid-template-columns: 1fr;
  grid-gap: 20px 50px;
  -webkit-transform: translateY(9%);
          transform: translateY(9%);
}

.creator-awards-logo-playscreen img {
  /*width: 300px;*/
  margin: 30px;
}

@media (max-width: 991px) {
  .mobilehide{
    display: none;
  }
  .top-text {
    font-size: 20px;
    font-weight: 700;
  }
  .grid-container {
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
  .grid-container-inner {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .grid-item {
    font-size: 1.5em;
    /* line-height: 2; */
  }
  .session-item {
    font-size: 2.6em;
    line-height: 2;
  }
  .grid-header {
    color: #fff;
    background: transparent;
    border: none;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    margin-top: 0px;
  }
  .grid-header h1 {
    font-size: 3em;
  }
  .creator-awards-logo img {
    width: 150px;
  }
  .creator-awards-logo-playscreen img {
    margin: 5px;
    max-width: 190px;
  }
  .creator-awards-logo-playscreen {
    position: relative;
    left: 0;
    top:10px;
    text-align: center;
  }
  .creator-awards-logo {
    position: relative;
    left: 0;
    text-align: center;
  }
  .grid-container-holding {
    grid-template-areas:
      "top-logo"
      "top-text";
    grid-template-columns: 1fr;
    grid-gap: 0;
    grid-template-rows: 300px 50px 100px;
  }
  .session .grid-container-holding {
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "top-logo"
    "top-text";
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .session .creator-awards-logo-holding img {
    max-width: 300px;
  }
  .grid-item-holding img {
    max-width: 300px;
  }
  .top-text h1 {
    text-align: center;
    font-size: 4em;
  }
  .grid-footer, hr.holding {
    display: none;
  }
  .grid-header.user {
    display: none;
  }
  .grid-header.creator-vote {
    display: block;
    padding: 5px 10px;
  }
  .grid-header.creator-vote h1 {
    margin-bottom: 15px;
  }
  .grid-item-company.user {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .grid-container, .grid-container-inner {
    grid-template-rows: repeat(auto-fit, minmax(0px, 1fr));
    grid-gap: 10px;
    /* margin-bottom: 100px; */
  }
}

@media (min-width: 992px) {
  .session .grid-item-holding.top-text h1 {
    font-size: 2em;
    padding-top: 0;
  }
  .session .grid-item-holding.top-text {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
  }
  .grid-header.creator-vote h1 span {
    font-size: .7em;
  }
  .grid-container.creator-vote {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .grid-container.user {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }
  .grid-item-holding.top-text {
    position: relative;
    right: 3%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .grid-container.creator-vote {
    grid-template-rows: repeat(3, minmax(100px,auto));
    max-width: 95%;
  }
  .grid-container.creator-vote .session-item {
    line-height: 1.7;
    font-size: 4em;
  }
}

@media (min-width: 1501px) and (max-width: 1649px) {
  .grid-container.creator-vote {
    max-width: 95%;
  }
}


@media (max-width: 767px) {
  .grid-item {
    max-width: 100%;
    /* line-height: 1; */
  }
  .session-item {
    max-width: 100%;
    line-height: 1;
  }
  .grid-item.creator-vote {
    font-size: 7vmax;
  }
  .grid-container-inner .grid-item {
    /* line-height: 1.25; */
  }

  .grid-container-inner .session-item {
    line-height: 1.25;
  }

  .session .grid-container-holding {
    grid-template-rows: 300px 100px;
  }

  .grid-item-holding.top-text > h1 {
    display: none;
  }
  .holding .grid-item-holding.top-text > h1 {
    display: hidden;
  }
  .top-text-mobile {
    display: block;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
  }
  .top-text h2 {
    font-size: 3.5em;
    padding: 0;
    color: #000;
    text-align: center;
    margin-top: 10px;
  }
  .top-text h3 {
    font-size: 1.5em;
    padding: 0;
    color: #000;
    text-align: center;
  }
}

@media (min-width: 768px) {
  /* .top-text-mobile {
    display: none;
  } */
}

@media (max-width: 550px) {
  .grid-item-company {
    max-width: 300px;
  }
  .grid-item-company.user {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin: auto;
  }
  .grid-item-holding img {
    max-width: 250px;
  }
  .grid-item-holding .no-connection img {
    max-width: 300px;
  }
  .grid-wrapper, .grid-wrapper.session {
    /* background-image: url(../images/background.png); */
  }
  .grid-wrapper.holding {
    /* background-image: url(../images/background.png); */
  }
  .grid-container-holding.user {
    -webkit-transform: translateY(8%);
            transform: translateY(8%);
    grid-template-rows: 280px 50px 100px;
  }
  .holding .grid-container-holding {
    -webkit-transform: translateY(7%);
            transform: translateY(7%);
    grid-template-rows: 275px 80px 100px;
  }
  .top-text h1 {
    font-size: 3em;
    padding: 0;
  }
  html,body {
    height: 100%;
  }
  .grid-container-holding {
    -webkit-transform: translateY(22%);
            transform: translateY(22%);
    grid-template-rows: 65px 200px 100px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-item {
    max-width: 100%;
  }
  .session-item {
    max-width: 100%;
  }
  .grid-item-company {
    width: 80%;
    max-width: 600px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .grid-item {
    font-size: 2em;
    /* line-height: 2; */
  }
  .session-item {
    font-size: 3em;
    line-height: 2;
  }
  .creator-awards-logo {
    top: 90px;
    left: 20px;
  }
  .creator-awards-logo img {
    width: 250px;
  }
  .top-text h1 {
    font-size: 9.5em;
    padding-top: 80px;
  }
}

@media (min-width: 1200px) {
  .session .grid-item-holding.top-text h1 {
    font-size: 2em;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .grid-item-holding.top-text h1 {
    font-size: 10.5em;
  }
}

@media (max-width: 1200px) {
  .grid-container-outer {
    grid-template-columns: 1fr;
  }
  .grid-container-inner {
    width: 95%;
    margin: auto;
  }
  .creator-awards-logo img {
    margin-top: 25px;
  }
  .grid-left .grid-header {
    background: transparent;
    color: #fff;
  }
}

@media (min-width: 1650px) {
  .grid-container {
    max-width: 1650px;
  }
  .grid-item {
    max-width: 100%;
  }
  .session-item {
    max-width: 100%;
  }
  .grid-item-company {
    margin-left: 35px;
    width: 80%;
  }
}

@media (min-width: 1920px) {
  .holding .grid-container-holding {
    grid-template-rows: 570px 100px 100px;
  }
  .grid-item-holding.top-text > h1 {
    font-size: 17em;
    padding-top: 25px;
  }
  .grid-item-holding img {
    max-width: 100%;
  }
  .grid-item-holding.top-text {
    right: 7%;
  }
  .session .grid-item-holding.top-text {
    right: 3%;
  }
  .creator-awards-logo img {
    width: 450px;
  }
  .grid-container-outer {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
}

@media (min-width: 2400px) {
  .grid-container-outer {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  .creator-awards-logo img {
    width: 100%;
    max-width: 800px;
  }
  .grid-container-inner {
    grid-template-rows: repeat(6, 120px);
    grid-gap: 35px 50px;
  }
  .grid-item {
    /* line-height: 1.55; */
  }
}

@media (max-width: 326px) {
  .grid-wrapper.session-not-holding {
    height: 100%;
  }
  .grid-container {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .grid-container.user {
    -webkit-transform: translateY(5%);
            transform: translateY(5%);
  }
}

/*.modal-open{overflow:hidden}*/

/*.modal{position:fixed;top:50%;right:0;bottom:0;left:50%;z-index:1050;display:none;overflow:hidden;outline:0;transform: translate(-50%, -50%);}*/

/*.modal-open .modal{overflow-x:hidden;overflow-y:auto}*/

/*.modal-dialog{position:relative;margin:.5rem;pointer-events:none;min-width:500px;width:fit-content}*/

/*.modal.fade .modal-dialog{transition:-webkit-transform .3s ease-out;transition:transform .3s ease-out,-webkit-transform .3s ease-out;-webkit-transform:translate(0,-25%);transform:translate(0,-25%)}*/
/*@media screen and (prefers-reduced-motion:reduce){.modal.fade .modal-dialog{transition:none}}*/

/*.modal.show .modal-dialog{-webkit-transform:translate(0,0);transform:translate(0,0)}*/
/*.modal-dialog-centered{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:calc(100% - (.5rem * 2))}*/

/*.modal-content{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;pointer-events:auto;background-color:#fff;background-clip:padding-box;border:1px solid rgba(0,0,0,.2);border-radius:.3rem;outline:0;padding:40px}*/

/*.modal-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040;background-color:#000}*/
/*.modal-backdrop.fade{opacity:0}*/
/*.modal-backdrop.show{opacity:.5}*/

/*.modal-header{display:-ms-flexbox;display:flex;-ms-flex-align:start;align-items:flex-start;-ms-flex-pack:justify;justify-content:space-between;padding:1rem;border-bottom:1px solid #e9ecef;border-top-left-radius:.3rem;border-top-right-radius:.3rem}*/
/*.modal-header .close{padding:1rem;margin:-1rem -1rem -1rem auto}*/

/*.modal-title{margin-bottom:0;line-height:1.5}*/

/*.modal-body{position:relative;-ms-flex:1 1 auto;flex:1 1 auto;padding:1rem}*/

/*.modal-footer{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;padding:1rem;border-top:1px solid #e9ecef}*/
/*.modal-footer>:not(:first-child){margin-left:.25rem}.modal-footer>:not(:last-child){margin-right:.25rem}*/

/*@media (min-width:576px){.modal-dialog{margin:1.75rem auto;}.modal-dialog-centered{min-height:calc(100% - (1.75rem * 2))}}*/

/*@media (max-width: 768px){*/
/*    .modal-dialog{*/
/*        max-width:95%;*/
/*        min-width: 95%;*/
/*        margin:5px auto 5px auto;*/
/*    }*/
/*    .modal-content{*/
/*        padding:10px*/
/*    }*/
/*    .modal {*/
/*        min-width: 95%;*/
/*    }*/
/*}*/

.modal {
    font-family: "Oswald", sans-serif;
    font-weight: 100;
}

.modal .modal-title {
    font-weight: 300;
    font-size: 35px;
}

.modal {
    display: flex !important;
    vertical-align: middle; }
.modal .modal-dialog {
    margin: auto; }
.modal .modal-header {
    display: inline;
    display: initial;
    text-align: center;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 2em; }
.modal .modal-header .modal-title {
    display: inline-block;
    color: #000000;
    font-family: "Oswald", sans-serif; }
.modal .modal-body {
    padding: 0;
    font-family: "Oswald", sans-serif; }
.modal .modal-body .question-box.question-form {
    border: none;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 2em; }
.modal .modal-body .question-box.question-form .form-control {
    height: 3em; }
.modal .modal-body .question-box.question-form .btn.btn-default.btn-admin {
    font-family: "Oswald", sans-serif;
    background: #0000ff;
    color: #000000;
    width: 65%;
    height: 3em;
    border: none;
}

.flex-container-scoreboard {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: row;
  background-size: cover;
}

.grid-wrapper-scoreboard {
  height: 100vh;
  /* background-image: url(../images/background.png); */
  background-size: cover;
  font-family: "Oswald", sans-serif;
  text-align: center;
  justify-items: center;
  /*text-transform: uppercase;*/
  /*padding: 0;*/
  /*margin: 0;*/
  overflow: auto;
}

.grid-container-scoreboard {
  background: transparent;
  /* box-shadow: 0 0.1em 0 0 rgba(0,0,0,0.1); */
  margin: auto;
  display: grid;
  /*grid-template-rows: repeat(auto-fill, minmax(100,1));*/
  grid-gap: 20px 50px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  padding: 0 10px 20px;
  clear: both;
}

